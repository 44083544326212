import React from 'react'

const className = 'font-sansSerif text-p3 antialiased md:text-p3md'
export const P3ClassName = className

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  as?: 'div' | 'span' | 'button'
  small?: boolean
}

const P3: React.FC<Props> = ({ children, className, as = 'p', ...other }) => {
  const Component = as as any
  return (
    <Component
      className={P3ClassName + (className ? ` ${className}` : '')}
      {...other}
    >
      {children}
    </Component>
  )
}

export default P3
